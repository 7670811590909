.about-me{
    height: 100%;
}

.about-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    border: 2px solid #fff;
    margin: 0px 60px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: #fff;
    box-shadow: 5px 5px 15px #000;
}

.about-header h2 {
    font-size: 55px;
    color: #f5a201;
    /* text-shadow: 1px 1px 5px #000; */
    font-family: "Bebas Neue", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.about-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.about-body .about-text-top {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    padding: 30px 10px;
    font-family: "Dosis", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    width: 85%;
}

.about-body .about-text-top p{
    padding: 20px 10px;
    text-align: center;
    font-size: 16px;
    font-family: "Dosis", sans-serif;
}

.about-body .about-text-top b{
    font-size: 38px;
}

.about-body .about-text-bottom{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 85%;
}

.about-body .about-text-bottom .about-bottom-left{
    background-color: #fff;
    flex-basis: 65%;
    width: 100%;
    height: 300px;
    border-radius: 15px;
    box-shadow: 5px 5px 15px #000;
}



.about-grid{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    text-align: center;

}

.about-grid-top{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    height: 50%;
    align-items: center;
    border-bottom: 5px solid #f5a201;
}

.about-grid-top .about-years{
    flex-basis: 50%;
    text-align: center;
    padding: 10px 0;
    border-right: 5px solid #f5a201;
}

.about-grid-top .about-years h3, .about-grid-top .about-projects h3{
    font-family: "Bebas Neue", sans-serif;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
}

.about-grid-top .about-years h3 span, .about-grid-top .about-projects h3 span{
    color: #f5a201;
    font-size: 60px;
}

.about-grid-top .about-projects{
    flex-basis: 50%;
    text-align: center;
    padding: 10px 0;
}



.about-grid-bottom{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    height: 50%;
    align-items: center;
    padding-bottom: 20px;
}

.about-grid-bottom h3{
    font-family: "Bebas Neue", sans-serif;
    font-weight: 700;
    font-size: 28px;
    text-align: center;
}

.about-grid-bottom h3 span{
    color: #f5a201;
    font-size: 60px;
    
}

.about-body .about-text-bottom .about-bottom-right{
    flex-basis: 35%;
    width: 100%;
    margin: 0 20px;
    height: 300px;
    background-color: #f5a201;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 15px;
    box-shadow: 5px 5px 15px #000;
}

.about-body .about-text-bottom .about-bottom-right h2{
    font-family: "Bebas Neue", sans-serif;
    font-weight: 700;
    font-size: 45px;
    color: #fff;
    text-shadow: 2px 2px 10px #000;
}

.about-body .about-text-bottom .about-bottom-right ul{
    list-style-type: none;
    font-size: 18px;
    font-family: "Dosis", sans-serif;
    font-weight: 700;
    width: 80%;
    color: #fff;
}



@media only screen and (max-width:600px){
    .about-me{
        height: 200vh;
    }
    .about-header{
        padding: 15px;
        margin: 0 30px;
        background-color: #f5a201;
        border: 2px solid #f5a201;

    }

    .about-header h2 {
        font-size: 45px;
        color: #fff;
    }
    .about-body .about-text-top{
        padding: 20px 5px;
        text-align: center;
        width: 75%;
    }

    .about-body .about-text-top p{
        padding: 10px 5px;
        text-align: justify;
        font-size: 14px;

    }
    .about-body .about-text-bottom{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 85%;
    }
    
    .about-body .about-text-bottom .about-bottom-left{
        flex-basis: 50%;
        margin-bottom: 50px;
    }
    
    
    
    .about-grid{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        text-align: center;
    
    }
    
    .about-body .about-text-bottom .about-bottom-right{
        flex-basis: 50%;

    }

    .about-body .about-text-bottom .about-bottom-right h2{
        padding-top: 20px;
    }

    .about-body .about-text-bottom .about-bottom-right ul{
        padding-bottom: 20px;
    }
}