.portfo-body{
  width: 100%;
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.portfo-body .portfo-left{
  flex-basis: 20%;
  background-color: #f5a201;
  height: 100%;
  position: sticky;
}

.portfo-body .portfo-right{
  flex-basis: 80%;
  background-color: #d2d2d2;
  height: 100%;
}

.navForMobile{
  display: none;
}

@media only screen and (max-width:600px){

  .portfo-body{
    margin-top: 250px;
    display: flex;
    flex-direction: column;
    height: 200vh;
  }
  .navForMobile{
    display: block;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .portfo-body .portfo-left{
    display: none;
  }
  .portfo-body .portfo-right{
    flex-basis: 70%;
  }

  .projects{
    margin-top: 100px;
  }
}