.home-parent-container{
    height: 800px;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.home-left, .home-right{
    flex-basis: 50%;
    height: 100%;
    width: 100%;
}

.home-left, .home-right{
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-image img{
    aspect-ratio: 3/2;
    width: 100%;
    height: 650px;
}

.home-text h3{
    font-size: 45px;
}

.home-text h1 span{
    font-size: 75px;
}

.home-text h4{
    width: 40%;
}

@media only screen and (max-width:600px){
    .home-parent-container{
        height: 100vh;
        width:100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column-reverse;
        margin-top: 225px;
    }

    /* .home-right{
        overflow: hidden;
    } */

    .home-image img{
        width: 100%;
        height: 140%;
        aspect-ratio: 3/2;
    }

    .home-left{
        margin-top: 100px;
        padding: 10px 20px;
    }

    .home-text h3{
        font-size: 32px;
    }

    .home-text h1 span{
        font-size: 45px;
    }

    .home-text h4{
        width: 100%;
        text-align: center;
    }
}