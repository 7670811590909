.my-contact {
    /* background-image: url("./assets/prjBg3.png"); */
    /* background-repeat: no-repeat;
    background-size: cover;*/
    overflow: hidden;
    background-color: #fff;
}

.contact-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.contact-body .left {
    flex-basis: 50%;
}

.contact-body .left img {
    width: 100%;
    height: 100%;
}

.contact-body .right {
    flex-basis: 50%;
    height: 400px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    flex-direction: column;
    margin: 10px 30px;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 15px;
    box-shadow: 5px 5px 15px #000;
    background: rgb(245, 162, 1);
    background: linear-gradient(180deg, rgba(245, 162, 1, 1) 0%, rgba(253, 207, 29, 1) 48%, rgba(255, 255, 255, 1) 100%);
}

.contact-body .right h4 {
    font-family: "Bebas Neue";
    font-size: 45px;
    font-weight: 700;
    color: #fff;
    text-shadow: 5px 2px 5px #000;
}

.contact-body .right ul {
    list-style-type: none;
    width: 60%;
}

.contact-body .right ul li {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
}

.contact-body .right ul li i {
    font-size: 40px;
    color: #fff;
    text-shadow: 4px 4px 5px #000;
}

.contact-body .right ul li a {
    text-decoration: none;
    color: #000;
    font-family: "Dosis";
    font-size: 24px;
    font-weight: 700;
}

@media only screen and (max-width: 600px) {
    .my-contact {
        overflow: hidden;

    }

    .contact-body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .contact-body .left img {
        width: 50%;
        height: 50%;
    }

    .contact-body .left {
        flex-basis: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .contact-body .right h4 {
        font-family: "Bebas Neue";
        font-size: 35px;
        font-weight: 700;
        color: #fff;
        text-shadow: 2px 2px 5px #000;
        padding: 20px 0;
    }

    .contact-body .right ul {
        width: 90%;
    }
    
    
}