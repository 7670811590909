

.navbar li{
    
    width: 115%;
    padding: 5px 15px;
    transition: all ease 0.5s;
    cursor: pointer;
   
}

.navbar li:hover{
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: #fff;
}

.active{
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: #fff;
}