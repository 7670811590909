.my-projects{
    background-image: url("./assets/prjBg3.png");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: auto;

}

.prj-header{
    text-align: center;
    background-color: #f5a201;
    margin: 0 100px;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    box-shadow: 5px 5px 15px #000;
}

.prj-header h2{
    font-family: "Bebas Neue";
    color: #fff;
    font-size: 60px;
    padding: 30px 0;
    text-shadow: 2px 2px 5px #000;
}

.prj-row1, .prj-row2{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.prj-subheader{
    text-align: center;
    padding: 30px 0;
}

.prj-subheader h4{
    font-size: 30px;
    font-family: "Dosis";
    font-weight: 700;
    color: #f5a201;
    text-shadow: 1px 1px 2px #000;
    width: 50%;
    padding: 10px 5;
}

.project-body .card{
    background-color: rgba(255,255,255,0.2);
    backdrop-filter: blur(15px);
}

.project-body .cont-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.project-body .cont-btn h4{
    text-align: center;
    background-color: #f5a201;
    width: 50%;
    padding: 10px 0;
    font-size: 32px;
    font-family: "Bebas Neue";
    font-weight: 700;
    color: #fff;
    text-shadow: 2px 2px 5px #000;
    letter-spacing: 5px;
    border-radius: 15px;
    box-shadow: 5px 5px 15px #000;
    cursor: pointer;
}

@media only screen and (max-width: 600px){

    .my-projects{
        background-image: url("./assets/prjMobBg3.jpeg");
        background-repeat: no-repeat;
        background-size: cover;
        overflow: auto;
    
    }

    .prj-header{
        margin: 0 30px;
        
    }

    .prj-row1, .prj-row2{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .project-body .card{
       margin: 25px 0;
    }

    .project-body .card-text{
        text-shadow: 1px 1px 2px #000;
    }

    .prj-subheader h4{
        width: 100%;
        backdrop-filter: blur(5px);
    }
    
}