.my-resume{
    height: 100%;
    background-color: #282c35;
}

.resume-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    border: 2px solid #fff;
    margin: 0px 60px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: #fff;
    box-shadow: 5px 5px 15px #000;
}

.resume-header h2 {
    font-size: 55px;
    color: #f5a201;
    /* text-shadow: 1px 1px 5px #000; */
    font-family: "Bebas Neue", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.card{
    border-radius: 15px;
    box-shadow: 5px 5px 10px #000;
}


@media only screen and (max-width: 600px){
    .my-resume{
        height: 200vh;
        width: 100%;
    }

    .resume-header{
        padding: 15px;
        margin: 0 30px;
        background-color: #f5a201;
        border: 2px solid #f5a201;
        width: 70%;
        transform: translateX(10%);

    }

    .resume-header h2 {
        font-size: 45px;
        color: #fff;
    }
}